import * as types from "./types";
import uniqBy from "lodash/uniqBy";
import * as conversationTypes from "@store-inbox/ConversationPanel/types";

const initialState = {
  loading: true,
  data: {},
  tickets: [],
  agents: {
    response: {},
    error: null,
  },
  activeTab: 0,
  payload: {
    page_size: 25,
    filters: {
      search: null,
      tag_filter: null,
      date_filter_start: null,
      date_filter_end: null,
      initiated_by: null,
      priority_filter: null,
      unread: null,
      conversation_source: null,
      ticket_status:
        JSON.parse(localStorage.getItem("ticket_status")) == ""
          ? "assigned"
          : JSON.parse(localStorage.getItem("ticket_status")),
      agent_ids: null,
      sort_by: null,
    },
    fetch_since: null,
  },
  connections: {
    response: {},
    error: null,
  },
  templates: {
    response: [],
    loading: true,
    error: null,
  },
  initiation: false,
  msgsCount: {},
  onlineStatus: {},
  popularTags: [],
  refreshStatus: "refreshing",
  ticket_create_status: null,
  labels: {
    response: {},
    error: null,
  },
  initiateMail: {
    response: {},
    error: null,
  },
  emailGroups: {
    response: [],
    error: null,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INIT: {
      return {
        ...state,
      };
    }
    case types.GET_CONVERSATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.RECEIVE_CONVERSATIONS: {
      if (state.payload.fetch_since === null) {
        return {
          ...state,
          loading: false,
          data: payload,
          tickets: payload.data.tickets,
          refreshStatus: "refreshed",
        };
      } else {
        return {
          ...state,
          loading: false,
          data: payload,
          tickets: uniqBy(
            [...state.tickets, ...payload.data.tickets],
            "ticket.id"
          ),
          payload: {
            ...state.payload,
            fetch_since: null,
          },
          refreshStatus: "refreshed",
        };
      }
    }
    case types.SET_CONVERSATIONS_TABS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case types.SET_CONVERSATIONS_ACTIVE_TAB: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case conversationTypes.RECEIVE_CONVERSATION: {
      const { data } = payload;
      const oldConversations = state.tickets;
      const newConversations = oldConversations.map((item) => {
        var temp = Object.assign({}, item);
        if (temp.conversation.id == data.conversation_id) {
          temp.conversation.read = true;
        }
        return temp;
      });
      return {
        ...state,
        loading: false,
        tickets: [...newConversations],
      };
    }
    case conversationTypes.NEW_MESSAGE: {
      const { data, currentConversationId } = payload;
      const oldConversations = state.tickets;
      const newMessage = data;
      let newConversations = [];
      if (
        oldConversations &&
        (oldConversations.some((a) => a.ticket.status === data.ticket.status) ||
          oldConversations.some(
            (a) => a.conversation.id !== data.conversation.id
          ))
      ) {
        newConversations = oldConversations.map((item) => {
          let temp = Object.assign({}, item);
          if (temp.ticket.id === data.ticket.id) {
            if (data.ticket.id == currentConversationId) {
              temp.conversation.last_message.read = true;
              temp.conversation.last_message =
                newMessage.conversation.new_message;
            } else {
              temp.conversation.last_message.read = false;
              temp.conversation.last_message =
                newMessage.conversation.new_message;
            }
          }
          return temp;
        });
      } else {
        newMessage.conversation.last_message =
          newMessage.conversation.new_message;
        newMessage.conversation.last_message.read = false;
        newConversations = [...oldConversations, { ...newMessage }];
      }
      return {
        ...state,
        loading: false,
        tickets: [...newConversations],
      };
    }
    case conversationTypes.SET_FILE_UPLOAD_SUCCESS: {
      const { data, currentConversationId } = payload;
      const newConversations = state.tickets.map((content) => {
        const newContent = Object.assign({}, content);
        if (newContent.conversation.id == currentConversationId) {
          newContent.conversation.last_message.attachment = Object.assign(
            {},
            data[data.length - 1].attachment
          );
          newContent.conversation.last_message.message = null;
          newContent.conversation.last_message.created_at =
            new Date().toISOString();
        }
        return newContent;
      });
      return {
        ...state,
        loading: false,
        tickets: [...newConversations],
      };
    }
    case conversationTypes.ADD_SUBSCRIBER_FEEDBACK: {
      const { data, currentUserId } = payload;
      let newConversations = state.tickets;
      if (data.ticket.new_subscriber.id == currentUserId) {
        newConversations = [data, ...state.tickets];
      }
      return {
        ...state,
        loading: false,
        tickets: newConversations,
      };
    }
    case conversationTypes.REMOVE_SUBSCRIBER_FEEDBACK: {
      const {
        data: { conversation_id, subscriber },
        currentUserId,
      } = payload;
      let newConversations;
      if (currentUserId === subscriber) {
        newConversations = state.tickets.filter((content) => {
          return content.id != conversation_id;
        });
      } else {
        newConversations = state.tickets;
      }
      return {
        ...state,
        loading: false,
        tickets: [...newConversations],
      };
    }
    case conversationTypes.UPDATE_MESSAGE_SENT: {
      const { data, currentConversationId, success } = payload;
      const oldConversations = state.tickets;
      if (success) {
        const newConversations = oldConversations.map((content) => {
          const newContent = Object.assign({}, content);
          if (newContent.conversation.id == currentConversationId) {
            newContent.conversation.last_message = {
              ...data,
              created_at: new Date().toISOString(),
              attachment: null,
            };
          }
          return newContent;
        });
        return {
          ...state,
          loading: false,
          tickets: [...newConversations],
        };
      } else {
        return {
          ...state,
          loading: false,
          tickets: [...oldConversations],
        };
      }
    }
    case types.SET_CONVERSATIONS_PAGINATION: {
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ...payload,
        },
      };
    }
    case types.SET_TAB_FILTERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.SET_CONVERSATIONS_SEARCH_FILTERS_BG: {
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ...payload,
        },
      };
    }
    case types.SET_CONVERSATIONS_SEARCH_FILTERS: {
      return {
        ...state,
        loading: true,
        payload: {
          ...state.payload,
          ...payload,
        },
      };
    }
    case types.GET_AGENTS_SUCCESS: {
      return {
        ...state,
        agents: {
          ...state.agents,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_AGENTS_ERROR: {
      return {
        ...state,
        agents: {
          ...state.agents,
          response: [],
          error: payload,
        },
      };
    }
    case types.GET_CONNECTIONS_SUCCESS: {
      return {
        ...state,
        connections: {
          ...state.connections,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_CONNECTIONS_ERROR: {
      return {
        ...state,
        connections: {
          ...state.connections,
          response: [],
          error: payload,
        },
      };
    }
    case types.GET_TEMPLATES: {
      return {
        ...state,
        templates: {
          ...state.templates,
          loading: true,
        },
      };
    }
    case types.GET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: {
          ...state.templates,
          response: payload,
          loading: false,
          error: null,
        },
      };
    }
    case types.GET_TEMPLATES_ERROR: {
      return {
        ...state,
        templates: {
          ...state.templates,
          loading: false,
          error: payload,
        },
      };
    }
    case types.CONVERSATION_INITIATED: {
      const { data } = payload;
      return {
        ...state,
        initiation: data.data,
      };
    }
    case types.FALSE_INITIATED: {
      return {
        ...state,
        initiation: {},
      };
    }
    case types.MSGS_COUNT_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        msgsCount: data.data,
      };
    }
    case types.MSGS_COUNT_UPDATE: {
      const { data } = payload;
      return {
        ...state,
        msgsCount: data,
      };
    }
    case types.UNASSIGNED_MSGS_COUNT_UPDATE: {
      const { data } = payload;
      return {
        ...state,
        msgsCount: {
          ...state.msgsCount,
          "whatsapp:unassigned": data["whatsapp:unassigned"],
          "instagram:unassigned": data["instagram:unassigned"],
          "messenger:unassigned": data["messenger:unassigned"],
          "email:unassigned": data["email:unassigned"],
          unassigned: data["unassigned"],
        },
      };
    }
    case types.SET_ONLINE_STATUS_ERROR: {
      return {
        ...state,
        onlineStatus: {
          ...state.onlineStatus,
          response: [],
          error: payload,
        },
      };
    }
    case types.GET_STATUS_SUCCESS: {
      return {
        ...state,
        onlineStatus: {
          ...state.onlineStatus,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_STATUS_ERROR: {
      return {
        ...state,
        onlineStatus: {
          ...state.onlineStatus,
          response: [],
          error: payload,
        },
      };
    }
    case types.SET_POPULAR_TAGS: {
      return {
        ...state,
        popularTags: payload.data.tags.reverse(),
      };
    }
    case types.GET_POPULAR_TAGS_SUCCESS: {
      return {
        ...state,
        popularTags: payload.tags.reverse(),
      };
    }
    case types.REFRESH_STATUS: {
      return {
        ...state,
        refreshStatus: payload.refreshStatus,
      };
    }
    case types.CREATE_NEW_TICKET_SUCCESS: {
      return {
        ...state,
        ticket_create_status: {
          success: true,
        },
      };
    }
    case types.CREATE_NEW_TICKET_ERROR: {
      return {
        ...state,
        ticket_create_status: {
          success: false,
          data: payload,
        },
      };
    }
    case types.GET_FIELDS_SUCCESS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_FIELDS_ERROR: {
      return {
        ...state,
        fields: {
          ...state.fields,
          response: [],
          error: payload,
        },
      };
    }
    case types.RESET_MAIL : {
      return {
        ...state,
        initiateMail: {
          ...state.initiateMail,
          response: {},
          error: null,
        },
      };
    }
    case types.INITIATE_MAIL_SUCCESS: {
      return {
        ...state,
        initiateMail: {
          ...state.initiateMail,
          response: payload,
          error: null,
        },
      };
    }
    case types.INITIATE_MAIL_FAIL: {
      return {
        ...state,
        initiateMail: {
          ...state.initiateMail,
          response: null,
          error: payload,
        },
      };
    }
    case types.GET_EMAIL_GROUPS_SUCCESS: {
      return {
        ...state,
        emailGroups: {
          ...state.emailGroups,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_EMAIL_GROUPS_ERROR: {
      return {
        ...state,
        emailGroups: {
          ...state.emailGroups,
          response: [],
          error: payload,
        },
      };
    }
    default:
      return state;
  }
};
